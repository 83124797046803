:root {
  --game-max-width: 500px;
  --header-height: 40px;
  --header-boarder-color: rgba(255, 255, 255, 0.1);
  --game-board-square-color: rgba(255, 255, 255, 0.2);
  --keyboard-height: 200px;
  --key-bg: grey;
  --key-text-color: white;
}

.App-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  padding: 0px 16px;
  height: var(--header-height);
  border-bottom: 1px solid var(--header-boarder-color);
  font-family: Arial, Helvetica, sans-serif;
}

.App-header-title {
  flex-grow: 2;
  font-weight: 700;
  font-size: 28px;
  letter-spacing: 0.01em;
  text-align: left;
  left: 0;
  right: 0;
  pointer-events: none;
  position: relative;
}

.nav-menu {
  display: flex;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
  border: 0;
  /* font: inherit; */
  font-family: Arial, Helvetica, sans-serif;
  vertical-align: baseline;
}

.help-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0 4px;
}

.help-button-icon {
  width: 24px;
  height: 24px;
  vertical-align: middle;
  cursor: pointer;
  font-family: inherit !important;

  font-size: 100%;
  line-height: 1.15;
}

.backspace-button-icon {
  width: 24px;
  height: 24px;
  vertical-align: middle;
  cursor: pointer;
  /* font-family: inherit !important; */
  font-family: Arial, Helvetica, sans-serif;
  font-size: 100%;
  line-height: 1.15;
}

.App {
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  background-color: #121212;
  width: 100%;
  height: 100%;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
}

body {
  padding: 0%;
  margin: 0;
  background-color: #121212;
}

nav {
  height: 30px;
  width: 100%;
  margin: 0;
  /* border-bottom: 1px solid silver; */
  padding-bottom: 1em;
  /* display: grid;
  place-items: center; */
  /* display: flex;
  justify-content: space-between; */
}

nav h1 {
  display: grid;
  place-items: center;
  margin: 0;
  /* font-family: Helvetica, Arial, sans-serif; */
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  font-size: 25px;
  padding-bottom: 0.15rem;
}

h3 {
  /* font-family: Helvetica, Arial, Helvetica, sans-serif; */
  font-family: Arial, Helvetica, sans-serif;
  padding-bottom: 0.2rem;
}

button {
  display: flex;
  justify-self: flex-end;
  text-decoration: none;
  font-size: larger;
  margin: 0;
}

.game {
  width: 100%;
  height: calc(100% - var(--header-height));
  /* height: calc(100% - 170px); */
  display: flex;
  align-items: center;
  /* padding-top: 79px; */
  flex-direction: column;
  overflow: hidden;
  max-height: 100%;
}

.board {
  width: 75%;
  /* height: 550px; */
  border: none;
  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
  height: 380px;
  margin-bottom: 8px;
}

.row {
  flex: 33%;
  display: flex;
  flex-direction: row;
  margin: 5px;
}

.letter {
  flex: 33%;
  height: 100%;
  border: 2px solid var(--game-board-square-color);
  /* border: 1px solid silver; */
  margin: 5px;
  display: grid;
  place-items: center;
  font-size: 30px;
  font-weight: bolder;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
}

.correct {
  background-color: #3bb033;
}

.almost {
  background-color: #ca80b8;
}

.error {
  background-color: #929098;
}

.keyboard {
  width: 100%;
  max-width: var(--game-max-width);
  height: var(--keyboard-height);
  margin: 0 8px;
  user-select: none;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  /* width: 75%; */
  /* height: 300px; */
  /* margin-top: 30px; */
}

.line {
  display: flex;
  width: 100%;
  margin: 0 auto 8px;
  touch-action: manipulation;
  vertical-align: baseline;
  user-select: none;
}

/* .line1, .line2, line3 {
  flex: 33%;
  flex-direction: row;
  display: flex;
  justify-content: center;
  margin: 3px;
} */

.key {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 1.25em;
  font-weight: bold;
  border: 0;
  padding: 0;
  margin: 0 6px 0 0;
  height: 58px;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  background-color: var(--key-bg);
  color: var(--key-text-color);
  overflow: visible;
  line-height: 1.15;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.3);
}

.key-space {
  flex: 0.5;
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  user-select: none;
}

/* .key {
  width: 50px;
  height: 70px;
  margin: 5px;
  border-radius: 4px;
  display: grid;
  place-items: center;
  font-size: 20px;
  background-color: grey;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  cursor: pointer;
} */

.big {
  /* width: 100px; */
  flex: 1.5;
  font-size: 12px;
}

.disabled {
  background-color: #3a393c;
}

.gameOver {
  /* font-family: Helvetica, Arial, sans-serif; */
  font-family: Arial, Helvetica, sans-serif;
}

@media screen and (min-width: 481px) {
  nav {
    height: 60px;
    width: 100%;
    margin: 0;
    border-bottom: 1px solid silver;
    padding-bottom: 1em;
  }

  nav h1 {
    display: grid;
    place-items: center;
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
    color: white;
    font-size: 45px;
  }

  h4 {
    padding: 0;
  }

  .game {
    width: 100%;
    height: calc(100% - 170px);
    display: flex;
    align-items: center;
    padding-top: 50px;
    flex-direction: column;
  }

  .board {
    width: 450px;
    height: 550px;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
  }

  .keyboard {
    width: 450px;
    height: 300px;
    margin-top: 60px;
  }
}
